@import "./_variable";

// common

.sub-heading {
  color: transparent;
  background: linear-gradient(to right, #7848f4, black);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 50px;
  font-family: $font-headings;
  line-height: $heading-line-height;
  letter-spacing: $heading-letter-spacing;
}

// bootcamp-hero-section
.bootcamp {
  .bootcamp-hero-section {
    h1 {
      color: transparent;
      background-color: $black;
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 72px;
      font-weight: 700;
      font-family: $font-headings;

      span {
        background: linear-gradient(to right, #7848f4, black);
        -webkit-background-clip: text;
      }
    }

    h3 {
      font-size: 40px;
      color: $black;
      font-family: $font-headings;
    }

    p {
      font-size: 20px;
      font-family: $font-text;
      letter-spacing: -0.3px;
    }

    .card-section {
      .card-a {
        h5 {
          color: $black;
        }
      }

      .card-b {
        h5 {
          color: $black;
        }
      }
    }
  }

  .next-icon {
    z-index: -1;
    opacity: 0.1;
    right: 30%;
    top: 20%;
  }
}
.why-started-section {
  .main-heading {
    font-size: 50px;
    font-weight: 600;
    background: linear-gradient(90deg, #673de6 0%, #1a1a1a 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font-headings;
  }

  .sub-heading {
    font-size: 24px;
    color: $black;
    font-weight: 400;
    font-family: $font-text;
  }

  .feature-box {
    border-radius: 20px;
    height: 100%;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &.problem-box {
      background: #fff1f0;
      border: 2px solid #ffebe9;

      .emoji-icon {
        color: #ff4d4f;
      }

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ff4d4f' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    &.vision-box {
      background: #f0f7ff;
      border: 2px solid #e6f4ff;

      .emoji-icon {
        color: #1890ff;
      }

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5z' fill='%231890ff' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    .emoji-icon {
      font-size: 40px;
      display: block;
    }

    .box-title {
      font-size: 32px;
      font-weight: 600;
      color: $black;
      font-family: $font-headings;
    }

    .box-subtitle {
      font-size: 25px;
      font-weight: 600;
      color: $light-voilet;
      font-family: $font-headings;
    }

    .box-text {
      font-size: 18px;
      color: #202124;
      position: relative;
      z-index: 2;
      font-family: $font-text;
    }
  }
}
.different-section {
  .highlight-badge {
    background: linear-gradient(45deg, #7848f4, #5c35c7);
    color: white;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 18px;
    font-weight: 500;
    font-family: $font-text;
  }

  .section-title {
    font-size: 50px;
    font-weight: 600;
    span {
      background: linear-gradient(to right, #7848f4, black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    font-family: $font-headings;
  }

  .feature-box {
    display: flex;
    align-items: flex-start;
    background: $white;
    border-radius: 16px;
    padding: 20px 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    height: 100%;
    transition: all 0.3s ease;

    .icon-wrapper {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      &.purple {
        background: rgba(120, 72, 244, 0.1);
        color: #7848f4;
      }
      &.blue {
        background: rgba(24, 144, 255, 0.1);
        color: #1890ff;
      }
      &.green {
        background: rgba(82, 196, 26, 0.1);
        color: #52c41a;
      }
      &.orange {
        background: rgba(250, 140, 22, 0.1);
        color: #fa8c16;
      }

      .feature-icon {
        font-size: 25px;
      }
    }

    .content-wrapper {
      h3 {
        font-size: 24px;
        font-weight: 600;
        color: $black;
        font-family: $font-headings;
      }

      p {
        color: #6c6c72;
        margin: 0;
        font-family: $font-text;
        font-size: 18px;
      }
    }
  }

  .cta-box {
    background-color: $primary-color;
    border-radius: 16px;
    padding: 40px 25px;

    h3 {
      font-size: 28px;
      font-weight: 600;
      color: $white;
      font-family: $font-headings;
    }

    p {
      font-size: 18px;
      opacity: 0.9;
      color: $white;
      font-family: $font-text;
    }
    .enroll {
      background-color: $white !important;
      color: $primary-color !important;
      &:hover {
        background-color: $primary-color !important;
        color: $white !important;
      }
    }
  }
}

.what-learn-section {
  h1 {
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
  }
  h5 {
    font-family: $font-headings;
  }
  .card-section {
    p {
      font-family: $font-text;
      font-size: $text-size;
    }

    .icon-section {
      color: $primary-color;
      border: 1.6px solid #808000;
      border-radius: 50%;
      transition: all ease-in-out 0.5ms;
    }

    .icon-section-2 {
      border: 1.6px solid #ffc0cb;
      border-radius: 50%;
      transition: all ease-in-out 0.5ms;
    }
  }
}

// why-join-section

.why-join-section {
  h1 {
    background: linear-gradient(to right, #7848f4, black);
    -webkit-background-clip: text;
  }

  .why-join-card {
    .circle-icon {
      width: 60px;
      height: 60px;
      display: flex;
    }

    h5 {
      font-family: $font-headings;
      line-height: $heading-line-height;
      letter-spacing: $heading-letter-spacing;
    }

    p {
      font-family: $font-text;
      font-size: $text-size;
    }

    .icon {
      color: $light-voilet;
    }

    .border-border-secondary {
      background-color: #cccccc;
    }

    .border-border-primary {
      background-color: #e2d8ff;
      color: $light-voilet;
    }
  }
}

// who join section
.who-join-section {
  .who-join-card-section {
    border-radius: 10px;
    background-color: #f0ebff;

    color: $light-voilet;
  }

  .heading-4 {
    color: $light-voilet;
    font-family: $font-headings;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .icon-section {
    color: $primary-color;
    font-size: 36px;
  }
}

//  enroll-section
.enroll-section {
  background-color: #e2d8ff;
  border-radius: 20px;

  h5 {
    color: $black;
    background-color: #ffffff;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  h1 {
    font-size: 45px;
    color: $dark-voilet;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    color: $dark-voilet;
    font-family: $font-text;
    font-size: $text-size;
  }
}

// success-story-section
.success-story-section {
  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .success-story-card {
    .icon-story {
      color: goldenrod;
    }

    .details-section {
      font-weight: 400;
      font-family: $font-text;
      font-size: $text-size;
    }
  }

  .card-body {
    height: 300px;
  }

  @media (min-width: 846px) {
    .card-body {
      height: 350px;
    }
  }
}

// get-start-section

.get-start-section {
  background-color: $primary-color;

  h1 {
    font-size: 40px;
    background-color: white;
    -webkit-background-clip: text;
    color: transparent;
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
    color: #ffffff;
  }
}

//  effective-fee-section
.effective-fee-section {
  .effective-fee-col-1 {
    background-color: $light-voilet;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    h1 {
      font-size: 40px;
      color: #ffffff;

      span {
        color: $black;
      }
    }

    p {
      color: #ffffff;
      font-family: $font-text;
      font-size: $text-size;
    }
  }

  .effective-fee-col-2 {
    .card-a {
      background-color: #e2d8ff;
      border-radius: 10px;
      width: 300px;

      h4 {
        font-family: $font-headings;
        line-height: $heading-line-height;
        letter-spacing: $heading-letter-spacing;
        font-weight: 500;
      }

      p {
        font-family: $font-text;
        font-size: $text-size;
      }
    }

    .card-b {
      background-color: #daefe0;
      border-radius: 10px;
      width: 300px;

      h4 {
        font-family: $font-headings;
        line-height: $heading-line-height;
        letter-spacing: $heading-letter-spacing;
        font-weight: 500;
      }

      p {
        font-family: $font-text;
        font-size: $text-size;
      }
    }
  }
}

.steps-section {
  h5 {
    font-family: $font-headings;
    line-height: $heading-line-height;
    letter-spacing: $heading-letter-spacing;
  }

  p {
    font-family: $font-text;
    font-size: $text-size;
  }
}

// effective section
.steps-section {
  .steps-border-section {
    border-radius: 50%;
    padding: 20px 30px;
    background-color: #daefe0;
  }
}

//  certificate section

.certificate-section {
  p {
    font-family: $font-text;
    font-size: $text-size;
  }

  .certificate-div {
    cursor: pointer;

    span {
      color: #ac92f9;
    }
  }

  .border-section {
    border: 1px solid #9011ff;
    border-radius: 10px;
    background-color: #e2d8ff;
  }

  .certificate-details-section-a {
    border-radius: 10px;
    background-color: $primary-color;
  }

  .certificate-details-section-b {
    background-color: white;

    .logo-section {
      width: 130px;
      height: auto;
    }

    .course-name-details {
      span {
        color: #9011ff;
      }
    }
  }
}

@media (max-width: 480px) {
  // common
  .sub-heading {
    font-size: 35px;
  }

  .bootcamp-hero-section {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    h1 {
      font-size: 40px !important;
    }

    h3 {
      font-size: 24px !important;
    }

    p {
      font-size: 16px;
    }
  }

  //  what learn section
  .what-learn-section {
    .card-section {
      h5 {
        font-size: 22px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  //  enroll-section
  .enroll-section {
    h5 {
      font-size: 22px;
    }

    h1 {
      font-size: 35px;
    }

    p {
      line-height: 18px;
    }
  }

  // why-join-section

  .why-join-section {
    .circle-icon {
      margin: 0 auto;
    }

    h5 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }

  // success-story-section
  .success-story-section {
    p {
      font-size: 18px;
    }

    h5 {
      font-size: 22px;
    }
  }

  // who join section
  .who-join-section {
    .who-join-card-section {
      font-size: 22px;
    }
  }

  //  effective fees

  .effective-fee-section {
    .effective-fee-col-1 {
      border-radius: 20px;

      h1 {
        font-size: 35px;
      }

      p {
        font-size: 18px;
      }
    }

    .effective-fee-col-2 {
      .card-a {
        width: 100%;
      }

      .card-b {
        width: 100%;
      }
    }
  }

  //  certificate section

  .certificate-section {
    p {
      font-size: 20px;
    }

    .certificate-div {
      cursor: pointer;

      span {
        color: #ac92f9;
      }
    }

    .border-section {
      border: 1px solid #9011ff;
      border-radius: 10px;
      background-color: #e2d8ff;
    }

    .certificate-details-section-a {
      border-radius: 10px;
      background-color: #9011ff;
    }

    .certificate-details-section-b {
      background-color: white;

      h3 {
        font-size: 20px;
      }

      p {
        font-size: 18px;
      }

      .authorized-section {
        width: 70px;

        h6 {
          font-size: 14px;
        }
      }

      .logo-section {
        width: 95px;
        height: auto;
      }
    }
  }

  .get-start-section {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
  .why-started-section {
    .main-heading {
      font-size: 32px;
    }

    .feature-box {
      padding: 25px;

      .box-title {
        font-size: 22px;
      }

      .box-subtitle {
        font-size: 20;
      }

      .box-text {
        font-size: 16px;
      }
    }
  }
  .different-section {
    .section-title {
      font-size: 32px;
    }

    .feature-box {
      .content-wrapper {
        h3 {
          font-size: 24px;
        }
      }
    }

    .cta-box {
      padding: 20px 0;
      text-align: center;

      h3 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  }
  .sub-heading {
    font-size: 32px;
  }
}
